import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

// Import styled components
import {
  MovieDetailsContainer,
  Headlining,
  DetailsDataContainer,
  AgeRestriction,
  ClockIcon,
  Duration,
  DetailsContainer,
  DetailsLabel,
  FacebookLink,
  VideoContainer,
  ImageContainer,
} from "./MovieInfoStyle";
import { Movies } from "../../models/Movies";

const MovieInfo = ({ movie, isShowVideo, isScreenMode }: Props) => {
  return (
    <>
      <MovieDetailsContainer>
        {isShowVideo ? (
          <Headlining>{movie.name}</Headlining>
        ) : isScreenMode ? (
          <>
            <Headlining>{movie.name}</Headlining>
          </>
        ) : (
          <>
            <Link to={`/o-filmu/${movie.slug}`}>
              <Headlining>{movie.name}</Headlining>
            </Link>
          </>
        )}
        <DetailsDataContainer
          isDirectionRow={true}
          isShowBorder={true}
          gridArea={""}
        >
          <AgeRestriction>{movie.age}+</AgeRestriction>
          <ClockIcon />
          <Duration>{movie.duration} minuta</Duration>
        </DetailsDataContainer>
        <DetailsDataContainer
          isDirectionRow={false}
          isShowBorder={true}
          gridArea={""}
        >
          <DetailsContainer>
            <DetailsLabel isBold={true}>Glumci:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.actors}</DetailsLabel>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsLabel isBold={true}>Redatelj:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.director}</DetailsLabel>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsLabel isBold={true}>Žanr:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.genre}</DetailsLabel>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsLabel isBold={true}>Jezik:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.language}</DetailsLabel>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsLabel isBold={true}>Država:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.state}</DetailsLabel>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsLabel isBold={true}>Godina:</DetailsLabel>
            <DetailsLabel isBold={false}>{movie.year}</DetailsLabel>
            <FacebookLink
              href="https://www.facebook.com/kinokrizevci"
              target="_blank"
            />
          </DetailsContainer>
        </DetailsDataContainer>
        {isShowVideo ? (
          <VideoContainer>
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url={movie.video_url}
            />
          </VideoContainer>
        ) : (
          <></>
        )}
      </MovieDetailsContainer>
      <ImageContainer
        bgImage={movie.poster_image_url}
        isDetailMovie={isShowVideo}
      />
    </>
  );
};

export default MovieInfo;

type Props = {
  movie: Movies;
  isShowVideo: boolean;
  isScreenMode: boolean;
};