import React from "react";

const Main = ({children}:Props) =>{
    return <>{children}</>
}

export default Main;

type Props = {
    children: string | JSX.Element
}