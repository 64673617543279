import React, { useState, useEffect } from "react";

// Import models
import { Projections } from "../../models/Projections";

// Import services
import { ProjectionsServices } from "../../services/ProjectionsServices";

// Import styled components
import {
  ProjectionTerminiContainer,
  TimeOfProjection,
  BuyTicket,
  BuyTicketLink,
} from "./ProjectionTerminiStyle";

const ProjectionTermini = ({ movieId, date, isScreenMode }: Props) => {
  const [projections, setProjections] = useState<Projections[]>([]);

  useEffect(() => {
    ProjectionsServices.getAllProjectionsForDate(1, movieId, date).then(
      (projections: Projections[]) => {
        setProjections(projections);
      }
    );
  }, [movieId, date]);

  return (
    <>
      {projections.map((projection) => (
        <>
          <ProjectionTerminiContainer>
            <TimeOfProjection>
              {projection.time.toString().substring(0, 5)}h
            </TimeOfProjection>
            {isScreenMode ? (
              <>
                <BuyTicket>ULAZNICE</BuyTicket>
              </>
            ) : (
              <>
                <BuyTicketLink href={`/kupnja-ulaznica/${projection.id}`}>
                  ULAZNICE
                </BuyTicketLink>
              </>
            )}
          </ProjectionTerminiContainer>
        </>
      ))}
    </>
  );
};

export default ProjectionTermini;

type Props = {
  movieId: number;
  date: Date;
  isScreenMode: boolean;
};
