import styled from "styled-components";
import { device } from "../../utils/Environment";

export const Auditorium = styled.div`
  background: #eaeaea;
  width: 100%;
  height: 380px;

  @media ${device.laptop} {
    width: 80%;
    margin: auto;
    height: 500px;
  }

  @media ${device.desktop} {
    height: 600px;
  }
`;

export const Canvas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0 0 100px 100px;
  background: #d9d9d9;

  @media ${device.laptop} {
    font-size: 1.3rem;
  }

  @media ${device.desktop} {
    font-size: 1.5rem;
  }
`;

export const SeatsContainer = styled.div`
  width: 100%;
  height: 300px;
  margin: 10px 0;
  padding: 0 5px;
  display: grid;
  grid-template-columns: 10px repeat(13, 25px);
  justify-content: space-evenly;
  align-items: center;
  grid-row-gap: 0;

  @media ${device.tablet} {
    padding: 0 10%;
  }

  @media ${device.laptop} {
    height: 400px;
    margin: 30px 0;
  }

  @media ${device.desktop} {
    height: 500px;
  }
`;

export const Seats = styled.button<Props>`
  width: 25px;
  height: 25px;
  background-image: url(${({ seatsStatus }: Props) => seatsStatus});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: inherit;
  border: none;

  @media ${device.tablet} {
    width: 30px;
    height: 30px;
  }

  @media ${device.laptop} {
    width: 50px;
    height: 50px;
  }

  @media ${device.desktop} {
    width: 60px;
    height: 60px;
  }
`;

export const Path = styled.div`
  width: 25px;
  height: 25px;

  @media ${device.tablet} {
    width: 30px;
    height: 30px;
  }

  @media ${device.laptop} {
    width: 50px;
    height: 50px;
  }

  @media ${device.desktop} {
    width: 60px;
    height: 60px;
  }
`;

export const Row = styled.span`
  width: 5px;
  height: 25px;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;

  @media ${device.tablet} {
    height: 30px;
  }

  @media ${device.laptop} {
    height: 50px;
    font-size: 1rem;
  }

  @media ${device.desktop} {
    height: 60px;
    font-size: 1.3rem;
  }
`;

export const Column = styled.span`
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;

  @media ${device.tablet} {
    height: 30px;
    width: 30px;
  }

  @media ${device.laptop} {
    height: 50px;
    width: 50px;
    font-size: 1rem;
  }

  @media ${device.desktop} {
    height: 60px;
    width: 60px;
    font-size: 1.3rem;
  }
`;

type Props = {
  seatsStatus: string;
};
