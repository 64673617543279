import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import styled components
import { Container, Text } from "./CanceledBuyingStyle";

export const CanceledBuying = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState<number>(15);

  useEffect(() => {
    setTimeout(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        navigate("/");
      }
    }, 1000);
  }, [counter]);

  return (
    <>
      <Container>
        <Text>Odustali se od kupnje ulaznica!</Text>
        <Text>
          Pričekajte {counter} sekundi ili kliknite na gumb ispod kako bi se
          vratili na stranicu kina.
        </Text>
        <Link to="/">POVRATAK</Link>
      </Container>
    </>
  );
};

export default CanceledBuying;
