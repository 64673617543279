import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

// Import styled components
import {
  MenuContainer,
  ButtonClose,
  NavigationContainer,
  InvalidsButton,
} from "./MobileMenuStyle";

const navigationText = {
  home: "Početna",
  schedule: "Raspored",
  comingSoon: "U dolasku",
};

const MobileMenu = ({ openMobileMenu, setOpenMobileMenu }: PropsHeader) => {
  return (
    <>
      {openMobileMenu ? (
        <MenuContainer>
          <ButtonClose onClick={() => setOpenMobileMenu(false)}>X</ButtonClose>
          <NavigationContainer>
            <Link to="/" onClick={() => setOpenMobileMenu(false)}>
              {navigationText.home}
            </Link>
            <Link
              to="/raspored-prikazivanja"
              onClick={() => setOpenMobileMenu(false)}
            >
              {navigationText.schedule}
            </Link>
            <Link
              to="/filmovi-u-dolasku"
              onClick={() => setOpenMobileMenu(false)}
            >
              {navigationText.comingSoon}
            </Link>
          </NavigationContainer>
          <InvalidsButton />
        </MenuContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileMenu;

type PropsHeader = {
  openMobileMenu: boolean;
  setOpenMobileMenu: Dispatch<SetStateAction<boolean>>;
};
