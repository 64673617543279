import styled from "styled-components";
import { device } from "../../utils/Environment";

export const ProjectionContainer = styled.div`
  margin: auto;
  min-height: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.laptop} {
    width: 100%;
    flex-direction: row;
  }
`;

export const Headlining = styled.h2`
  font-family: "Alata";
  font-size: 2rem;
  text-align: center;
`;

export const SubHeadlining = styled.h3`
  font-family: "Alata";
  font-size: 2rem;
  line-height: 44px;
  text-align: center;
`;

export const Line = styled.hr`
  margin: 20px auto;
  width: 80%;
  border: 1px solid #000000;
`;
