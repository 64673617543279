import styled from "styled-components";
import { device } from "../../utils/Environment";

export const Container = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & a {
    width: 200px;
    height: 50px;
    font-size: 1.3rem;
    text-align: center;
  }
`;

export const Text = styled.p`
  margin-bottom: 20px;
  text-align: center;
  font-size: 2rem;
`;
