import styled from "styled-components";
import { device } from "../../utils/Environment";

export const HeadliningContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 25px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background: #dfdfdf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Alata";

  @media ${device.desktop} {
    justify-content: center;
  }
`;

export const FilmContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${device.laptop} {
    width: 30%;
  }

  @media ${device.desktop} {
    width: 20%;
  }
`;

export const FilmHeadlining = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 0.7rem;
  color: #000000;

  @media ${device.laptop} {
    font-size: 1rem;
  }
`;

export const Date = styled.p`
  font-size: 0.6rem;
  font-weight: 400;

  @media ${device.laptop} {
    font-size: 0.9rem;
  }
`;
export const Time = styled.p`
  font-size: 0.6rem;
  font-weight: 400;

  @media ${device.laptop} {
    font-size: 0.9rem;
  }
`;

export const TicketsNumber = styled.b`
  text-align: center;
  font-size: 0.8rem;
  width: 30%;

  @media ${device.laptop} {
    font-size: 1rem;
  }
`;

export const BuyButton = styled.button`
  min-width: 20%;
  height: 20px;
  background: #ffb800;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 0.7rem;
  text-transform: uppercase;

  &:hover {
    color: #ffb800;
    background: #fff;
  }

  @media ${device.laptop} {
    min-width: 200px;
    height: 50px;
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  height: 75px;

  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 2vh;
  margin-left: 5%;
  margin-bottom: 2vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  justify-content: space-evenly;
`;
export const PriceText = styled.p`
  font-size: 0.8rem;

  @media ${device.laptop} {
    font-size: 1rem;
  }
`;