import React from "react";

// Import styled components
import { MainContainer } from "./ContainerStyle";

const Container = ({ children, isScreenMode }: Props) => {
  return (
    <>
      <MainContainer isScreenMode={isScreenMode}>{children}</MainContainer>
    </>
  );
};

export default Container;

type Props = {
  children: string | JSX.Element;
  isScreenMode: boolean;
};
