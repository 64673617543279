import React from "react";

// Import components
import CanceledBuying from "../components/CanceledBuying/CanceledBuying";

const SellingCanceledPage = () => {
  return (
    <>
      <CanceledBuying />
    </>
  );
};

export default SellingCanceledPage;
