import styled from "styled-components";
import { device } from "../../utils/Environment";

//Import image
import bgImage from "../../assets/img/footer-bg.png";

export const FooterElement = styled.footer`
  width: 100%;
  color: #ffffff;
  min-height: 200px;
  background: #151515 url(${bgImage}) no-repeat;
  background-size: cover;
  text-align: center;
  padding-bottom: 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }

  & a {
    color: #55f5ff;
    text-decoration: none;

    @media ${device.tablet} {
      font-size: 20px;
    }
  }
`;

export const Headlining = styled.h3`
  padding-top: 15px;
  padding-bottom: 5px;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const Description = styled.p`
  padding: 0 20px;
  font-size: 14px;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const ButtonLegalOpen = styled.button`
  background: #151515;
  color: #55f5ff;
  text-decoration: none;
  border: 0;
  padding-top: 5px;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.laptop} {
    cursor: pointer;
  }
`;

export const SubHeadlining = styled.h4`
  padding-top: 15px;
  padding-bottom: 5px;

  @media ${device.tablet} {
    font-size: 22px;
  }
`;

export const LinkElement = styled.a`
  text-decoration: none;
  color: #55f5ff;
  padding-bottom: 10px;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const WorkingHourAndAddressMainContainer = styled.div`
  display: flex;
`;

export const WorkingHourAndAddressContainer = styled.div``;

export const PartnersLogoContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

export const PartnerLogoLink = styled.a`
  padding-right: 20px;

  & img {
    max-width: 70px;

    @media ${device.laptop} {
      max-width: 120px;
    }
  }
`;
