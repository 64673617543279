const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const corvusPayStoreId: number = 27251;
export const corvusPayStoreIdTesting: number = 27767;
export const corvusPayVersion: string = "1.3";
export const corvusPayValuta: string = "EUR";
export const corvusPaySignKey: string = "UpQZMaTSttXAOwXlOmgPPfnD4";
export const corvusPaySignKeyTesting: string = "wet7yqotU2sE0L64HDCyDY2Oe";

