import React, { Dispatch, SetStateAction } from "react";

// Import styled component
import {
  MainContainer,
  PopupContainer,
  Headlining,
  Description,
  CloseContainer,
  ButtonClose,
} from "./LegalPopupStyle";

const LegalPopup = ({ openedPopup, setOpenedPopup }: PropsHeader) => {
  return (
    <>
      {openedPopup ? (
        <MainContainer>
          <PopupContainer>
            <CloseContainer>
              <ButtonClose onClick={() => setOpenedPopup(false)}>X</ButtonClose>
            </CloseContainer>
            <Headlining>PUČKO OTVORENO UČILIŠTE KRIŽEVCI</Headlining>
            <Description>
              Ustanova za trajno obrazovanje i kulturu, čiji je osnivač Grad
              Križevci
            </Description>
            <Description>Adresa: Trg. sv. Florijana 14</Description>
            <Description>OIB: 69478236897</Description>
            <Description>KONTAKT PODATCI:</Description>
            <Description>email: ravnateljica@pou-krizevci.hr</Description>
            <Description>Telefon: +385 (0)48 711 212</Description>
            <Description>RADNO VRIJEME:</Description>
            <Description>Ponedjeljak-Petak</Description>
            <Description>07:00h - 15:00h</Description>
          </PopupContainer>
        </MainContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default LegalPopup;

type PropsHeader = {
  openedPopup: boolean;
  setOpenedPopup: Dispatch<SetStateAction<boolean>>;
};
