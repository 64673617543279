import styled from "styled-components";
import { device } from "../../utils/Environment";

//Import images
import KinoLogo from "../../assets/img/logo/kino_krizevci_logo.png";
import DropDown from "../../assets/img/dropdown.png";

export const HeaderElement = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(255, 184, 0, 0.21) 0%,
    #ffb800 90.45%
  );

  @media ${device.tablet} {
    height: 100px;
  }
`;

export const Logo = styled.div`
  width: 100px;
  height: 60px;
  background: url(${KinoLogo}) no-repeat;
  background-size: contain;
  background-position: center;

  @media ${device.laptop} {
    width: 200px;
    height: 80px;
  }
`;

export const Navigation = styled.nav`
  display: none;

  @media ${device.tablet} {
    display: block;
  }

  & a {
    margin-right: 20px;
    color: #002f46;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;

    &:hover {
      border-bottom: 3px solid;
      border-image-slice: 1;
      border-width: 3px;
      border-image-source: linear-gradient(to right, #002f46, #002f4600);
    }
  }
`;

export const NavigationMobile = styled.button`
  border: 0;
  width: 60px;
  height: 60px;
  background: url(${DropDown}) no-repeat;
  background-size: contain;
  background-position: center;

  @media ${device.tablet} {
    display: none;
  }
`;
