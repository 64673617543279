import React from "react";

// Import components
import SucceedBuying from "../components/SucceedBuying/SucceedBuying";

const SellingSucceedPage = () => {
  return (
    <>
      <SucceedBuying />
    </>
  );
};

export default SellingSucceedPage;
