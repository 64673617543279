import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { MovieServices } from "../../services/MoviesService";

// Import models
import { Movies } from "../../models/Movies";

// Import styled components
import {
  ScheduleContainer,
  HeadingContainer,
  LeftArrow,
  RightArrow,
} from "./ScheduleSectionStyled";

// Import components
import Card from "../Card/Card";

const ScheduleSection = ({ isArrival }: Props) => {
  const [moviesList, setMoviesList] = useState<Movies[]>([]);

  useEffect(() => {
    if (isArrival) {
      MovieServices.getMoviesForInArrival(1).then((movies) => {
        setMoviesList(movies);
      });
    } else {
      MovieServices.getMoviesForHomeSchedule(1).then((movies) => {
        setMoviesList(movies);
      });
    }
  }, [isArrival]);

  function changeActiveMovie(isRight: Boolean) {
    let newMovieOrder: Movies[] = [];
    switch (moviesList.length) {
      case 2:
        newMovieOrder.push(moviesList[1]);
        newMovieOrder.push(moviesList[0]);
        break;
      case 3:
        if (isRight) {
          newMovieOrder.push(moviesList[1]);
          newMovieOrder.push(moviesList[2]);
          newMovieOrder.push(moviesList[0]);
        } else {
          newMovieOrder.push(moviesList[2]);
          newMovieOrder.push(moviesList[0]);
          newMovieOrder.push(moviesList[1]);
        }
        break;
      default:
        break;
    }

    setMoviesList(newMovieOrder);
  }

  return (
    <>
      <ScheduleContainer>
        <HeadingContainer>
          <Link
            to={isArrival ? "/filmovi-u-dolasku" : "/raspored-prikazivanja"}
          >
            {isArrival ? "Pogledaj sve najave" : "Pogledaj cijeli raspored"}
          </Link>
        </HeadingContainer>
        {moviesList.map((movie: Movies, index: number) => {
          return (
            <Card
              key={uuidv4()}
              bgImageUrl={movie.poster_image_url}
              order={index}
              heading={movie.name}
              link={`/o-filmu/${movie.slug}`}
            />
          );
        })}
        {moviesList.length > 1 ? (
          <>
            <LeftArrow onClick={() => changeActiveMovie(true)} key={uuidv4()} />
            <RightArrow
              onClick={() => changeActiveMovie(false)}
              key={uuidv4()}
            />
          </>
        ) : (
          <></>
        )}
      </ScheduleContainer>
    </>
  );
};

export default ScheduleSection;

type Props = {
  isArrival: boolean;
};
