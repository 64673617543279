import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

// Import styled component
import {
  HeaderElement,
  Logo,
  Navigation,
  NavigationMobile,
} from "./HeaderStyle";

const navigationText = {
  schedule: "Raspored",
  comingSoon: "U dolasku",
};

const Header = ({ openMobileMenu }: PropsHeader) => {
  return (
    <>
      <HeaderElement>
        <Link to="/">
          <Logo />
        </Link>
        <Navigation>
          <Link to="/raspored-prikazivanja">{navigationText.schedule}</Link>
          <Link to="/filmovi-u-dolasku">{navigationText.comingSoon}</Link>
        </Navigation>
        <NavigationMobile
          onClick={() => openMobileMenu(true)}
        ></NavigationMobile>
      </HeaderElement>
    </>
  );
};

export default Header;

type PropsHeader = {
  openMobileMenu: Dispatch<SetStateAction<boolean>>;
};
