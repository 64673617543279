import styled from "styled-components";
import { device } from "../../utils/Environment";

// Import images
import IconClock from "../../assets/img/clock.png";
import FacebookIcon from "../../assets/img/facebook-icon.png";

export const MovieDetailsContainer = styled.div`
  width: 100%;

  @media ${device.laptop} {
    grid-area: content-header;
  }

  & a {
    text-decoration: none;
    color: #000000;
  }
`;

export const Headlining = styled.h1`
  font-family: Alata;
  font-size: 2.2rem;
  line-height: 44px;
}
`;

export const DetailsDataContainer = styled.div<PropsContainer>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDirectionRow }: PropsContainer) =>
    isDirectionRow ? "row" : "column"};
  border-bottom: 1px solid
    ${({ isShowBorder }: PropsContainer) =>
      isShowBorder ? "#000000" : "transparent"};
  padding: 10px 0;

  @media ${device.laptop} {
    grid-area: ${({ gridArea }: PropsContainer) => gridArea};
  }
`;

export const AgeRestriction = styled.label`
  color: #ffffff;
  background: #ffb800;
  text-align: center;
  margin-right: 10px;
`;

export const Duration = styled.label`
  font-family: "Alata";
  font-size: 1.2em;
  color: #ffb800;
`;

export const ClockIcon = styled.label`
  width: 20px;
  height: 20px;
  background: url(${IconClock}) no-repeat;
  background-size: cover;
  margin-right: 10px;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 0;
`;

export const DetailsLabel = styled.label<Props>`
  padding-right: 25px;
  min-width: 25%;
  font-weight: ${({ isBold }: Props) => (isBold ? "bold" : "normal")};

  @media ${device.tablet} {
    min-width: 15%;
  }
`;

export const FacebookLink = styled.a`
  position: relative;
  left: 42%;
  width: 25px;
  height: 25px;
  background: url(${FacebookIcon}) no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    width: 35px;
    height: 35px;
    left: 65%;
  }
`;

export const VideoContainer = styled.div`
  display: none;
  margin: 10px 0;
  width: 80%;
  height: 350px;

  @media ${device.laptop} {
    display: block;
  }
`;

export const ImageContainer = styled.div<PropsImage>`
  margin: 20px 0;
  align-self: center;
  width: 250px;
  height: 400px;
  background: url(${({ bgImage }: PropsImage) => bgImage}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(8px 4px 31px rgba(0, 0, 0, 0.65));

  @media ${device.tablet} {
    width: ${({ isDetailMovie }: PropsImage) =>
      isDetailMovie ? "400px" : "300px"};
    height: ${({ isDetailMovie }: PropsImage) =>
      isDetailMovie ? "600px" : "400px"};
  }

  @media ${device.laptop} {
    grid-area: image-header;
  }
`;

type Props = {
  isBold: boolean;
};

type PropsContainer = {
  isDirectionRow: boolean;
  isShowBorder: boolean;
  gridArea: string;
};

type PropsImage = {
  bgImage: string;
  isDetailMovie: boolean;
};
