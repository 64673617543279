import { Projections } from "../models/Projections";
import { SealingSeats } from "../models/SalingSeats";

export class ProjectionsServices {
  private static apiUrl: string = "https://shark-app-xoiff.ondigitalocean.app";

  public static getAllDateProjectionsForMovie(
    cinemaId: number,
    movieId: number
  ) {
    return fetch(
      `${this.apiUrl}/projections/movie/${cinemaId}&${new Date()
        .toISOString()
        .substring(0, 10)}&${movieId}`
    )
      .then((response) => response.json())
      .then((data) => data.projections)
      .catch(() => []);
  }

  public static getAllProjectionsForDate(
    cinemaId: number,
    movieId: number,
    date: Date
  ) {
    return fetch(
      `${this.apiUrl}/projections/movie/date/${cinemaId}&${new Date(date)
        .toISOString()
        .substring(0, 10)}&${movieId}`
    )
      .then((response) => response.json())
      .then((data) => data.projections)
      .catch(() => []);
  }

  public static getAllOccupiedSeatsOnProjection(projectionId: number) {
    return fetch(`${this.apiUrl}/occupied-seats/projection/${projectionId}`)
      .then((response) => response.json())
      .then((data) => data.occupiedSeats)
      .catch(() => []);
  }

  public static getProjectionById(projectionId: number) {
    return fetch(`${this.apiUrl}/projections/${projectionId}`)
      .then((response) => response.json())
      .then((data) => data.projection)
      .catch(() => []);
  }

  public static addOccupiedSeatsToProjection(
    projection: Projections,
    customerName: string,
    customerLastName: string,
    customerEmail: string,
    seats: number[]
  ) {
    let buySeats: SealingSeats[] = [];
    for (const seat of seats) {
      let sellingSeat: SealingSeats = new SealingSeats(
        customerName,
        customerLastName,
        customerEmail,
        true,
        new Date(),
        new Date(),
        false,
        seat,
        projection.id
      );
      buySeats.push(sellingSeat);
    }

    return buySeats;
  }

  public static succeedBuy(
    projectionId: number,
    price: number,
    buyingCode: string
  ) {
    return fetch(
      `${this.apiUrl}/occupied-seats/edit/code-used/${projectionId}&${price}&${buyingCode}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}
