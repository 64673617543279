export class MovieServices {
  private static apiUrl: string = "https://shark-app-xoiff.ondigitalocean.app";

  public static async getMoviesForHero(cinemaId: number) {
    return fetch(`${this.apiUrl}/movies/cinema-hero/${cinemaId}`)
      .then((response) => response.json())
      .then((data) => data.movies)
      .catch(() => []);
  }

  public static async getMoviesForHomeSchedule(cinemaId: number) {
    return fetch(`${this.apiUrl}/movies/cinema-first-in-projection/${cinemaId}`)
      .then((response) => response.json())
      .then((data) => data.movies)
      .catch(() => []);
  }

  public static async getMoviesForInArrival(cinemaId: number) {
    return fetch(`${this.apiUrl}/movies/in-arrival/${cinemaId}`)
      .then((response) => response.json())
      .then((data) => data.movies)
      .catch(() => []);
  }

  public static async getMoviesBySlug(cinemaId: number, slug: string) {
    return fetch(`${this.apiUrl}/movies/slug/${slug}&${cinemaId}`)
      .then((response) => response.json())
      .then((data) => data.movie)
      .catch(() => undefined);
  }

  public static async getAllMoviesForInArrival(cinemaId: number) {
    return fetch(`${this.apiUrl}/movies/in-arrival/all/${cinemaId}`)
      .then((response) => response.json())
      .then((data) => data.movies)
      .catch(() => []);
  }

  public static async getAllMovies(cinemaId: number) {
    return fetch(
      `${this.apiUrl}/movies/all/${cinemaId}&${new Date()
        .toISOString()
        .substring(0, 10)}`
    )
      .then((response) => response.json())
      .then((data) => data.movies)
      .catch(() => []);
  }
}
