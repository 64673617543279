import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Import model
import { Movies } from "../../models/Movies";

// Import services
import { MovieServices } from "../../services/MoviesService";

// Import components
import MovieInfo from "../MovieInfo/MovieInfo";
import ProjectionSchedule from "../ProjectionSchedule/ProjectionSchedule";

// Import styled components
import {
  MovieDetailsSection,
  SubHeadlining,
  DetailsDataContainer,
  Text,
} from "./MovieDetailsStyle";

const MovieDetails = () => {
  const { name } = useParams();
  const [movie, setMovie] = useState<Movies>();

  useEffect(() => {
    MovieServices.getMoviesBySlug(1, name!).then((movie: Movies) => {
      setMovie(movie);
    });
  }, [name]);

  return (
    <>
      {movie !== undefined ? (
        <>
          <MovieDetailsSection>
            <MovieInfo movie={movie} isShowVideo={true} isScreenMode={false} />
            <DetailsDataContainer
              isDirectionRow={false}
              isShowBorder={false}
              gridArea={"content-description"}
            >
              <SubHeadlining>Radnja filma</SubHeadlining>
              <Text>{movie.action}</Text>
            </DetailsDataContainer>
            <DetailsDataContainer
              isDirectionRow={false}
              isShowBorder={false}
              gridArea={"content-projections"}
            >
              <SubHeadlining>Vrijeme prikazivanja</SubHeadlining>
              {movie.inArrival ? (
                <>
                  <ProjectionSchedule
                    isInArrival={true}
                    movieId={movie.id}
                    key={uuidv4()}
                    isScreenMode={false}
                  />
                </>
              ) : (
                <>
                  <ProjectionSchedule
                    isInArrival={false}
                    movieId={movie.id}
                    key={uuidv4()}
                    isScreenMode={false}
                  />
                </>
              )}
            </DetailsDataContainer>
          </MovieDetailsSection>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MovieDetails;
