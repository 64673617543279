import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Import model
import { Movies } from "../../models/Movies";

// Service import
import { MovieServices } from "../../services/MoviesService";

// Import styled components
import {
  MovieSection,
  PosterImage,
  Container,
  ContainerPlayTrailer,
  ContainerContent,
  Headlining,
  ButtonFindMore,
  ButtonPlayTrailer,
  LeftArrow,
  RightArrow,
} from "./HeroSectionStyle";

const HeroSection = () => {
  const [moviesList, setMoviesList] = useState<Movies[]>([]);

  useEffect(() => {
    MovieServices.getMoviesForHero(1).then((movies) => {
      setMoviesList(movies);
    });
  }, []);

  function changeActiveMovie(isRight: Boolean) {
    let newMovieOrder: Movies[] = [];
    switch (moviesList.length) {
      case 2:
        newMovieOrder.push(moviesList[1]);
        newMovieOrder.push(moviesList[0]);
        break;
      case 3:
        if (isRight) {
          newMovieOrder.push(moviesList[1]);
          newMovieOrder.push(moviesList[2]);
          newMovieOrder.push(moviesList[0]);
        } else {
          newMovieOrder.push(moviesList[2]);
          newMovieOrder.push(moviesList[0]);
          newMovieOrder.push(moviesList[1]);
        }
        break;
      default:
        break;
    }
    setMoviesList(newMovieOrder);
  }

  return (
    <>
      {moviesList.map((movie: Movies, index: number) => {
        return (
          <>
            <MovieSection
              key={uuidv4()}
              bgImage={movie.bg_image_url}
              left={0}
              isActive={index === 0}
            >
              <PosterImage
                key={uuidv4()}
                bgImage={movie.poster_image_url}
                left={0}
                isActive={true}
              />
              <Container key={uuidv4()}>
                <ContainerPlayTrailer>
                  <ButtonPlayTrailer></ButtonPlayTrailer>
                </ContainerPlayTrailer>
                <ContainerContent>
                  <Headlining>{movie.name}</Headlining>
                  <Link to={"/o-filmu/" + movie.slug}>
                    <ButtonFindMore>Saznaj više</ButtonFindMore>
                  </Link>
                </ContainerContent>
              </Container>
              {moviesList.length > 1 ? (
                <>
                  <LeftArrow onClick={() => changeActiveMovie(false)} />
                  <RightArrow onClick={() => changeActiveMovie(true)} />
                </>
              ) : (
                <></>
              )}
            </MovieSection>
          </>
        );
      })}
    </>
  );
};

export default HeroSection;
