import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// Import models
import { Projections } from "../../models/Projections";

// Import services
import { ProjectionsServices } from "../../services/ProjectionsServices";

// Import components
import ProjectionTermini from "../ProjectionTremin/ProjectionTermini";

// Import styled component
import {
  ProjectionContainer,
  Headlining,
  SubHeadlining,
  Line,
} from "./ProjectionScheduleStyle";

const ProjectionSchedule = ({ isInArrival, movieId, isScreenMode }: Props) => {
  const [projectionsDate, setProjectionsDate] = useState<Projections[]>([]);
  const days = [
    "Nedjelja",
    "Ponedjeljak",
    "Utorak",
    "Srijeda",
    "Četvrtak",
    "Petak",
    "Subota",
  ];

  useEffect(() => {
    ProjectionsServices.getAllDateProjectionsForMovie(1, movieId).then(
      (projectionsDate: Projections[]) => {
        setProjectionsDate(projectionsDate);
      }
    );
  }, [movieId]);

  return (
    <>
      {projectionsDate.length > 0 ? (
        projectionsDate.map((projectionDate) => (
          <>
            <Headlining>
              {days[new Date(projectionDate.date).getDay()] + " "}
              {projectionDate.date
                .toString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join(".")}
            </Headlining>
            <ProjectionContainer key={uuidv4()}>
              <ProjectionTermini
                key={uuidv4()}
                movieId={movieId}
                date={new Date(projectionDate.date)}
                isScreenMode={isScreenMode}
              />
            </ProjectionContainer>
            <Line />
          </>
        ))
      ) : (
        <>
          {isInArrival ? (
            <>
              <ProjectionContainer>
                <SubHeadlining>Dolazi uskoro!</SubHeadlining>
              </ProjectionContainer>
              <Line />
            </>
          ) : (
            <>
              <ProjectionContainer>
                <SubHeadlining>
                  Za ovaj film trenutno ne postoji niti jedna projekcija!
                </SubHeadlining>
              </ProjectionContainer>
              <Line key={uuidv4()} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProjectionSchedule;

type Props = {
  movieId: number;
  isInArrival: boolean;
  isScreenMode: boolean;
};
