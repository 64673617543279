import React, { Dispatch, SetStateAction } from "react";

// Import styled components
import {
  MainContainer,
  PopupContainer,
  HeaderContainer,
  ButtonClose,
  Logo,
  FilmContainer,
  FilmHeadlining,
  DateTimeContainer,
  Date,
  Time,
  TableContainer,
  RowContainer,
  TableRowHeader,
  TableRow,
  FormContainer,
  InputContainer,
  InputLabel,
  Input,
  BuyButton,
  NotificationText,
} from "./SellingPopupStyle";
import { Projections } from "../../models/Projections";

const SellingPopup = ({
  projection,
  numberOfTickets,
  setName,
  setLastName,
  setEmail,
  handleClick,
  error,
  isOpen,
  setOpenPopup,
}: Props) => {
  return (
    <>
      {isOpen ? (
        <>
          <MainContainer>
            <PopupContainer>
              <HeaderContainer>
                <Logo />
                <ButtonClose
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                >
                  X
                </ButtonClose>
              </HeaderContainer>
              <FilmContainer>
                <FilmHeadlining>{projection?.moviename}</FilmHeadlining>
                <DateTimeContainer>
                  <Date>
                    Datum:{" "}
                    {projection?.date
                      .toString()
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}
                    {"."}
                  </Date>
                  <Time>
                    Vrijeme: {projection?.time.toString().substring(0, 5)}h
                  </Time>
                </DateTimeContainer>
              </FilmContainer>
              <NotificationText>
                Sve ulaznice koje nisu plaćene preko sustava biti će nakon
                određenog vremena izbrisane iz sustava!
              </NotificationText>
              <NotificationText>
                Sustav ne radi rezervacija iako su sjedala označena kao zauzeta!
              </NotificationText>
              <TableContainer>
                <RowContainer>
                  <TableRowHeader>Broj Ulaznica</TableRowHeader>
                  <TableRowHeader>Cijena</TableRowHeader>
                  <TableRowHeader>Ukupno</TableRowHeader>
                </RowContainer>
                <RowContainer>
                  <TableRow>{numberOfTickets}</TableRow>
                  <TableRow>{projection.price} €</TableRow>
                  <TableRow>{numberOfTickets * projection.price} €</TableRow>
                </RowContainer>
              </TableContainer>
              <FormContainer>
                <InputContainer>
                  <InputLabel>Ime:</InputLabel>
                  <Input
                    type="text"
                    error={error}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Prezime:</InputLabel>
                  <Input
                    type="text"
                    error={error}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Email:</InputLabel>
                  <Input
                    type="email"
                    error={error}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </InputContainer>
                <BuyButton onClick={handleClick}>Kupi</BuyButton>
              </FormContainer>
            </PopupContainer>
          </MainContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SellingPopup;

type Props = {
  projection: Projections;
  numberOfTickets: number;
  setName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  handleClick: () => void;
  error: boolean;
  isOpen: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
};
