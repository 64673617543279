import styled from "styled-components";
import { device } from "../../utils/Environment";

// Import images
import KinoLogo from "../../assets/img/logo/kino_krizevci_logo.png";

export const MainContainer = styled.section`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div`
  width: 100%;
  min-height: 50%;
  background: #ffffff;
  padding: 20px;
  text-align: center;
  border: 2px solid #ffb800;
  border-radius: 15px;

  @media ${device.tablet} {
    width: 70%;
    min-height: 30%;
  }

  @media ${device.laptop} {
    width: 50%;
  }

  @media ${device.desktop} {
    width: 30%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 100px;
  height: 100px;
  background: url(${KinoLogo}) no-repeat;
  background-size: contain;

  @media ${device.tablet} {
    width: 150px;
  }

  @media ${device.laptop} {
    width: 200px;
  }
`;

export const ButtonClose = styled.button`
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 0;
  font-size: 40px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export const FilmContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
  }
`;

export const FilmHeadlining = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  color: #000000;

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const DateTimeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Date = styled.p`
  font-size: 0.8rem;
  font-weight: 400;

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;
export const Time = styled.p`
  font-size: 0.8rem;
  font-weight: 400;

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const TableRowHeader = styled.p`
  width: 33%;
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;

  &:last-child {
    border-right: 2px solid transparent;
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const TableRow = styled.p`
  width: 33%;
  border-right: 2px solid #000;

  &:last-child {
    border-right: 2px solid transparent;
  }

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  gap: 10px;

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptop} {
    width: 50%;
  }
`;

export const InputLabel = styled.label`
  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const Input = styled.input<Props>`
  width: 90%;
  border: 1px solid ${({ error }: Props) => (error ? "#F00" : "#000")};
  border-radius: 5px;
  padding: 0 10px;

  @media ${device.laptop} {
    font-size: 1.2rem;
  }
`;

export const BuyButton = styled.button`
  min-width: 40%;
  height: 30px;
  background: #ffb800;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    color: #ffb800;
    background: #fff;
  }

  @media ${device.laptop} {
    min-width: 30%;
    height: 50px;
  }
`;

export const NotificationText = styled.p`
  color: red;
  padding: 2px 20px;
  font-size: 0.8rem;
  font-weight: 700;
`;

type Props = {
  error: boolean;
};
