import React from "react";

// Import styled components
import {
  CardContainer,
  Heading,
  HoverContainer,
  PlayButton,
  HoverInfo,
} from "./CardStyle";

const Card = ({ bgImageUrl, order, heading, link }: Props) => {
  switch (order) {
    case 0:
      return (
        <>
          <CardContainer bgImage={bgImageUrl} isLeft={false} isMain={true}>
            <HoverContainer>
              <PlayButton />
              <HoverInfo href={link}>informacije</HoverInfo>
            </HoverContainer>
          </CardContainer>
          <Heading>{heading}</Heading>
        </>
      );
    case 1:
      return (
        <CardContainer bgImage={bgImageUrl} isLeft={true} isMain={false}>
          <HoverContainer>
            <PlayButton />
            <HoverInfo href={link}>informacije</HoverInfo>
          </HoverContainer>
        </CardContainer>
      );
    case 2:
      return (
        <CardContainer bgImage={bgImageUrl} isLeft={false} isMain={false}>
          <HoverContainer>
            <PlayButton />
            <HoverInfo href={link}>informacije</HoverInfo>
          </HoverContainer>
        </CardContainer>
      );
    default:
      return <></>;
  }
};

export default Card;

type Props = {
  bgImageUrl: string;
  heading: string;
  order: number;
  link: string;
};
