import styled from "styled-components";
import { device } from "../../utils/Environment";

export const MainContainer = styled.div<Props>`
  max-width: 100%;
  min-height: ${({ isScreenMode }: Props) =>
    isScreenMode ? "250px" : "500px"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;

  @media ${device.laptop} {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 40% 60%;
    grid-template-areas: "image-header content-header";

    margin: 20px 5%;
  }

  @media ${device.laptopL} {
    margin: 20px 18%;
  }
`;

type Props = {
  isScreenMode: boolean;
};
