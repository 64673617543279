import React from "react";
import { v4 as uuidv4 } from "uuid";

// Import components
import HeroSection from "../components/HeroSection/HeroSection";
import ScheduleSection from "../components/ScheduleSection/ScheduleSection";

const HomePage = () => {
  return (
    <>
      <HeroSection key={uuidv4()} />
      <ScheduleSection isArrival={false} key={uuidv4()} />
      <ScheduleSection isArrival={true} key={uuidv4()} />
    </>
  );
};

export default HomePage;
