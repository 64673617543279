import styled from "styled-components";
import { device } from "../../utils/Environment";

//Import images
import InvalidsIcon from "../../assets/img/accessibility.jpg";

export const MenuContainer = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  background: #ffb800 96.87%;
  border: 3px solid rgba(255, 184, 0, 1);
  z-index: 999;
`;

export const ButtonClose = styled.button`
  width: 50px;
  height: 50px;
  background: #ffb800 96.87%;
  border: 0;
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
`;

export const NavigationContainer = styled.section`
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  background-color: inherit;
  justify-content: center;
  align-items: center;

  & a {
    width: 60%;
    color: #002f46;
    text-decoration: none;
    font-weight: bold;
    font-size: 2em;
    margin-top: 20px;
    border-bottom: 2px solid black;
    text-align: end;
  }
`;

export const InvalidsButton = styled.button`
  width: 50px;
  height: 50px;
  background: url(${InvalidsIcon});
  background-size: contain;
  background-position: center;
  border: 0;
  margin-right: 10px;
`;
