import React from "react";
import { v4 as uuidv4 } from "uuid";

// Import components
import MovieDetails from "../components/MovieDetails/MovieDetails";

const MoviePage = () => {
  return (
    <>
      <MovieDetails key={uuidv4()} />
    </>
  );
};

export default MoviePage;
