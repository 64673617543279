import styled from "styled-components";
import { device } from "../../utils/Environment";

// Import images
import palyBtn from "../../assets/img/play-button.svg";
import palyBtnHover from "../../assets/img/play-button-hover.svg";
import leftArrow from "../../assets/img/left-arrow-hero.png";
import rightArrow from "../../assets/img/right-arrow-hero.png";

export const MovieSection = styled.section<Props>`
  display: none;

  @media ${device.laptop} {
    background: rgb(0, 0, 0, 0.87) url(${({ bgImage }: Props) => bgImage})
      no-repeat;
    background-size: cover;
    width: 100%;
    height: 700px;
    display: ${({ isActive }: Props) => (isActive ? "flex" : "none")};
    justify-content: center;
    align-items: flex-end;
    left: ${({ left }: Props) => left}vw;
  }
`;

export const PosterImage = styled.div<Props>`
  width: 400px;
  height: 600px;
  position: absolute;
  left: 150px;
  top: 150px;
  background: rgb(0, 0, 0, 0.87) url(${({ bgImage }: Props) => bgImage})
    no-repeat;
  background-size: cover;
`;

export const Container = styled.div`
  width: 100%;
  height: 30%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 100px;
  border-bottom: 2px solid #ffb800;
  border-top: 0.5px solid #ffb800;
`;

export const ContainerPlayTrailer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 2%;
`;

export const ContainerContent = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Headlining = styled.h1`
  color: #ffffff;
  font-size: 1.5em;
  margin-bottom: 20px;
`;

export const ButtonFindMore = styled.button`
  border: 2px solid #ffb800;
  border-radius: 5px;
  font-size: 1.3em;
  background: rgba(0, 0, 0, 0.44);
  color: #ffb800;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(255, 184, 0, 0.5) 0%,
      rgba(255, 184, 0, 0) 100%
    );
    color: white;
  }
`;

export const ButtonPlayTrailer = styled.button`
  width: 55px;
  height: 55px;
  background: rgba(0, 0, 0, 1) url(${palyBtn}) no-repeat;
  background-size: 60%;
  background-position: center;
  border: 2px solid #ffb800;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: rgb(255, 184, 0) url(${palyBtnHover}) no-repeat;
    background-size: 60%;
    background-position: center;
  }
`;

export const RightArrow = styled.button`
  position: absolute;
  top: 45%;
  right: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: rgb(0, 0, 0, 0) url(${rightArrow}) no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
`;
export const LeftArrow = styled.button`
  position: absolute;
  top: 45%;
  left: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: rgb(0, 0, 0, 0) url(${leftArrow}) no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
`;

type Props = {
  bgImage: string;
  left: number;
  isActive: boolean;
};
