import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// Import models
import { Movies } from "../models/Movies";

// Import services
import { MovieServices } from "../services/MoviesService";

// Import components
import MovieInfo from "../components/MovieInfo/MovieInfo";
import Container from "../components/Container/Container";
import ProjectionSchedule from "../components/ProjectionSchedule/ProjectionSchedule";

const DisplaySchedulePage = ({ isScreenMode }: Props) => {
  const [movies, setMovies] = useState<Movies[]>([]);

  useEffect(() => {
    MovieServices.getAllMovies(1).then((movies) => {
      setMovies(movies);
    });
  }, []);

  return (
    <>
      {movies.length > 0 ? (
        movies.map((movie) => (
          <>
            <Container isScreenMode={isScreenMode}>
              <MovieInfo
                movie={movie}
                isShowVideo={false}
                isScreenMode={isScreenMode}
              />
            </Container>
            <ProjectionSchedule
              isInArrival={false}
              movieId={movie.id}
              key={uuidv4()}
              isScreenMode={isScreenMode}
            />
          </>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default DisplaySchedulePage;

type Props = {
  isScreenMode: boolean;
};