import React, { useState } from "react";
import { Link } from "react-router-dom";

// Import components
import LegalPopup from "../LegalPopup/LegalPopup";

// Import styled component
import {
  FooterElement,
  ContentContainer,
  Headlining,
  Description,
  ButtonLegalOpen,
  SubHeadlining,
  LinkElement,
  WorkingHourAndAddressMainContainer,
  WorkingHourAndAddressContainer,
  PartnersLogoContainer,
  PartnerLogoLink,
} from "./FooterStyle";

// Import images
import pouLogo from "../../assets/img/logo/pou_krizevci_logo.png";
import kinoMrezaLogo from "../../assets/img/logo/kino_mreza_logo.png";
import corvusLogo from "../../assets/img/payment/corvus-pay-logo.png";
import dinersLogo from "../../assets/img/payment/diners-logo.jpg";
import maestroLogo from "../../assets/img/payment/maestro-logo.png";
import mastercardLogo from "../../assets/img/payment/mastercard-logo.png";
import visaLogo from "../../assets/img/payment/visa-logo.jpg";

const Footer = () => {
  const [openedPopup, setOpenedPopup] = useState(false);
  return (
    <>
      <FooterElement>
        <ContentContainer>
          <Headlining>Kino Križevci</Headlining>
          <Description>
            Kino Križevci nalazi se u prostorima Razvojnog centra i tehnološkog
            parka. Kinom upravlja Pučko otvoreno učilište Križevci.
          </Description>
          <ButtonLegalOpen onClick={() => setOpenedPopup(true)}>
            Pravni Podaci
          </ButtonLegalOpen>
          <SubHeadlining>Pratite nas</SubHeadlining>
          <LinkElement
            href="https://www.facebook.com/kinokrizevci"
            target="_blank"
          >
            Facebook
          </LinkElement>
          <WorkingHourAndAddressMainContainer>
            <WorkingHourAndAddressContainer>
              <SubHeadlining>Adresa</SubHeadlining>
              <Description>Ulica Franje Tuđmana 20</Description>
              <Description>48260 Križevci</Description>
              <LinkElement
                href="https://www.google.com/maps/place/Razvojni+centar+i+tehnolo%C5%A1ki+park+Kri%C5%BEevci/@46.019369,16.5422977,15z/data=!4m5!3m4!1s0x0:0x5976ba609b0ce97!8m2!3d46.019369!4d16.5422977"
                target="_blank"
              >
                Pronađite nas na karti
              </LinkElement>
            </WorkingHourAndAddressContainer>
            <WorkingHourAndAddressContainer>
              <SubHeadlining>Radno vrijeme</SubHeadlining>
              <Description>Pon.-Pet. 07-15 sati</Description>
              <Description>kino@pou-krizevci.hr</Description>
              <Description>048/711-212</Description>
              <Link to="/paravila-privatnosti">
                Izjava o privatnosti i Uvjeti Korištenja
              </Link>
            </WorkingHourAndAddressContainer>
          </WorkingHourAndAddressMainContainer>
        </ContentContainer>
        <PartnersLogoContainer>
          <PartnerLogoLink href="https://www.pou-krizevci.hr/" target="_blank">
            <img src={pouLogo} alt="Pučko otvoreno učilište Križevci" />
          </PartnerLogoLink>
          <PartnerLogoLink href="https://kinomreza.hr/" target="_blank">
            <img src={kinoMrezaLogo} alt="Kino mreža" />
          </PartnerLogoLink>
          <PartnerLogoLink href="https://www.corvuspay.com/" target="_blank">
            <img src={corvusLogo} alt="Corvus pay" />
          </PartnerLogoLink>
          <PartnerLogoLink
            href="https://www.mastercard.hr/hr-hr.html"
            target="_blank"
          >
            <img src={mastercardLogo} alt="Mastercard" />
          </PartnerLogoLink>
          <PartnerLogoLink
            href="https://www.mastercard.hr/hr-hr/privatni/pronadite-karticu.html"
            target="_blank"
          >
            <img src={maestroLogo} alt="Maestro" />
          </PartnerLogoLink>
          <PartnerLogoLink href="https://www.visa.com.hr/" target="_blank">
            <img src={visaLogo} alt="Visa" />
          </PartnerLogoLink>
          <PartnerLogoLink href="https://www.diners.hr/hr" target="_blank">
            <img src={dinersLogo} alt="Diners" />
          </PartnerLogoLink>
        </PartnersLogoContainer>
        <Description>
          Create by{" "}
          <LinkElement href="https://endcoding.hr" target="_blank">
            End Coding d.o.o.
          </LinkElement>
        </Description>
      </FooterElement>
      <LegalPopup openedPopup={openedPopup} setOpenedPopup={setOpenedPopup} />
    </>
  );
};

export default Footer;
