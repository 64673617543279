import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

// Import models
import { Movies } from "../models/Movies";

// Import services
import { MovieServices } from "../services/MoviesService";

// Import components
import MovieInfo from "../components/MovieInfo/MovieInfo";
import Container from "../components/Container/Container";

const MoviesComingSoonPage = () => {
  const [movies, setMovies] = useState<Movies[]>([]);

  useEffect(() => {
    MovieServices.getAllMoviesForInArrival(1).then((movies) => {
      setMovies(movies);
    });
  }, []);

  return (
    <>
      {movies.length > 0 ? (
        movies.map((movie) => (
          <>
            <Container key={uuidv4()} isScreenMode={false}>
              <MovieInfo
                movie={movie}
                isShowVideo={false}
                key={uuidv4()}
                isScreenMode={false}
              />
            </Container>
          </>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default MoviesComingSoonPage;
