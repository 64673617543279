import styled from "styled-components";
import { device } from "../../utils/Environment";

export const MovieDetailsSection = styled.section`
  max-width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;

  @media ${device.laptop} {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 40% 60%;
    grid-template-areas:
      "image-header content-header"
      "content-description content-description"
      "content-projections content-projections";

    margin: 20px 5%;
  }

  @media ${device.laptopL} {
    margin: 20px 10%;
  }
`;

export const SubHeadlining = styled.h2`
  font-family: "Alata";
  font-size: 2rem;
  line-height: 44px;
  color: "#ffb800";
`;

export const DetailsDataContainer = styled.div<PropsContainer>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isDirectionRow }: PropsContainer) =>
    isDirectionRow ? "row" : "column"};
  border-bottom: 1px solid
    ${({ isShowBorder }: PropsContainer) =>
      isShowBorder ? "#000000" : "transparent"};
  padding: 10px 0;

  @media ${device.laptop} {
    grid-area: ${({ gridArea }: PropsContainer) => gridArea};
  }
`;

export const Text = styled.p`
  white-space: pre-wrap;
`;

type PropsContainer = {
  isDirectionRow: boolean;
  isShowBorder: boolean;
  gridArea: string;
};
