import styled from "styled-components";
import { device } from "../../utils/Environment";

export const MainContainer = styled.section`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div`
  width: 100%;
  min-height: 50%;
  background: #ffffff;
  padding: 20px;
  text-align: center;

  @media ${device.tablet} {
    width: 70%;
  }
`;

export const Headlining = styled.h1`
  font-size: 1.1rem;
  color: #ffb800;

  @media ${device.tablet} {
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 0;

  @media ${device.tablet} {
    font-size: 1.3rem;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonClose = styled.button`
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 0;
  font-size: 40px;
  font-weight: bold;

  @media ${device.laptop} {
    cursor: pointer;
  }
`;
