import React from "react";

// Import components
import Tickets from "../components/Tickets/Tickets";

const BuyingTicketsPage = () => {
  return (
    <>
      <Tickets />
    </>
  );
};

export default BuyingTicketsPage;
