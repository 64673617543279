import styled from "styled-components";
import { device } from "../../utils/Environment";

export const ProjectionTerminiContainer = styled.div`
  height: 40px;
  width: 100%;
  text-align: center;
  background: #c2bfbf;
  border-radius: 10px;

  display: flex;
  align-items: center;

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.laptop} {
    width: 20%;
  }
`;

export const TimeOfProjection = styled.label`
  width: 35%;
  font-size: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
`;

export const BuyTicketLink = styled.a`
  width: 65%;
  height: 100%;
  text-decoration: none;
  color: white;
  background: #ffb800;
  border-radius: 10px;
  padding-top: 1vh;
  transition: all 0.7s ease;
  font-size: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
`;

export const BuyTicket = styled.p`
  width: 65%;
  height: 100%;
  text-decoration: none;
  color: white;
  background: #ffb800;
  border-radius: 10px;
  padding-top: 1vh;
  transition: all 0.7s ease;
  font-size: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
`;