import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Import styled components
import { Container, Text } from "./SucceedBuyingStyle";

const SucceedBuying = () => {
  const navigate = useNavigate();

  const [counter, setCounter] = useState<number>(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        clearInterval(interval);
        navigate("/");
      }
    }, 1000);
  }, [counter]);

  return (
    <>
      <>
        <Container>
          <Text>
            Uspješno ste kupili ulaznice! Provjerite da li se u vašem e-mail
            pretinacu nalazi kod za ulaznice!
          </Text>
          <Text>
            Pričekajte {counter} sekundi ili kliknite na gumb ispod kako bi se
            vratili na stranicu kina.
          </Text>
          <Link to="/">POVRATAK</Link>
        </Container>
      </>
    </>
  );
};

export default SucceedBuying;
