import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Components import
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Footer from "./components/Footer/Footer";
import MobileMenu from "./components/MobileMenu/MobileMenu";

// Import pages
import HomePage from "./pages/Home";
import DisplaySchedulePage from "./pages/DisplaySchedule";
import MoviesComingSoonPage from "./pages/MoviesComingSoon";
import MoviePage from "./pages/Movie";
import BuyingTicketsPage from "./pages/BuyingTickets";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import SellingCanceledPage from "./pages/SellingCanceled";
import SellingSucceedPage from "./pages/SellingSucceed";

function App() {
  const [openedMobileMenu, setOpenedMobileMenu] = useState(false);

  return (
    <>
      <Header openMobileMenu={setOpenedMobileMenu} />
      <Main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/raspored-prikazivanja"
            element={<DisplaySchedulePage isScreenMode={false} />}
          />
          <Route
            path="/raspored-screen"
            element={<DisplaySchedulePage isScreenMode={true} />}
          />
          <Route path="/filmovi-u-dolasku" element={<MoviesComingSoonPage />} />
          <Route path="/o-filmu/:name" element={<MoviePage />} />
          <Route
            path="/kupnja-ulaznica/:projectionId"
            element={<BuyingTicketsPage />}
          />
          <Route path="/paravila-privatnosti" element={<PrivacyPolicyPage />} />
          <Route path="/uspijeh" element={<SellingSucceedPage />} />
          <Route path="/odustao" element={<SellingCanceledPage />} />
        </Routes>
      </Main>
      <Footer />
      <MobileMenu
        openMobileMenu={openedMobileMenu}
        setOpenMobileMenu={setOpenedMobileMenu}
      />
    </>
  );
}

export default App;
