import styled from "styled-components";
import { device } from "../../utils/Environment";

// Import images
import playBtn from "../../assets/img/play-button-card-index.png";
import playBtnHover from "../../assets/img/play-button-card-index-hover.png";

export const CardContainer = styled.div<Props>`
  position: absolute;
  left: ${({ isLeft, isMain }: Props) => (isLeft && !isMain ? "40px" : "")};
  right: ${({ isLeft, isMain }: Props) =>
    !isLeft && isMain ? "110px" : "40px"};
  top: 100px;
  width: 200px;
  height: 300px;
  background: url(${({ bgImage }: Props) => bgImage}) no-repeat;
  background-size: cover;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
  border-radius: 20px;
  z-index: ${({ isMain }: Props) => (isMain ? "5" : "0")};

  @media ${device.tablet} {
    background-size: contain;
    left: ${({ isLeft, isMain }: Props) => (isLeft && !isMain ? "20%" : "")};
    right: ${({ isLeft, isMain }: Props) =>
      !isLeft && isMain ? "calc(40% - 20px)" : "20%"};
  }

  @media ${device.laptop} {
    height: 400px;
    width: 300px;
    left: ${({ isLeft, isMain }: Props) => (isLeft && !isMain ? "25%" : "")};
    right: ${({ isLeft, isMain }: Props) =>
      !isLeft && isMain ? "42%" : "25%"};
  }

  & a {
    text-decoration: none;
    height: 10%;
  }
`;

export const Heading = styled.div`
  position: relative;
  top: 350px;
  font-size: 1.2em;
  text-align: center;

  text-decoration: none;
  color: #ffffff;

  @media ${device.laptop} {
    display: none;
  }
`;

export const HoverContainer = styled.div`
  display:none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;

  &:hover{
    background: linear-gradient(0deg, rgba(255, 184, 0, 0.7), rgba(255, 184, 0, 0.7));
    box-shadow: 8px 4px 31px rgba(0, 0, 0, 0.65);

    & button, a {
      display: flex;
      background-color: inherit;
    }
  }

  @media ${device.laptop} {
    display: flex;
  }
}`;

export const PlayButton = styled.button`
  background: url(${playBtn}) no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
  border: 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  position: relative;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    background: url(${playBtnHover}) no-repeat;
  }
`;

export const HoverInfo = styled.a`
  width: 180px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  display: none;
  color: #ffffff;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;

  &:hover {
    border: 2px solid #000000;
    color: #000000;
  }
`;

type Props = {
  bgImage: string;
  isLeft: boolean;
  isMain: boolean;
};
