import styled from "styled-components";
import { device } from "../../utils/Environment";

// Import images
import leftArrow from "../../assets/img/left-arrow-hero.png";
import rightArrow from "../../assets/img/right-arrow-hero.png";

export const ScheduleContainer = styled.section`
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #151515;
  border-bottom: 1px solid #5b5b5b;

  @media ${device.laptop} {
    height: 600px;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;

  @media ${device.laptop} {
    height: 100px;
  }

  & a {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
    font-size: 1em;
    line-height: 28px;

    @media ${device.laptop} {
      font-size: 1.5em;
    }
  }
`;

export const LeftArrow = styled.button`
  position: absolute;
  top: 250px;
  left: 10px;
  width: 25px;
  height: 25px;
  background: url(${leftArrow}) no-repeat;
  background-size: cover;
  border: none;

  @media ${device.laptop} {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const RightArrow = styled.button`
  position: absolute;
  top: 250px;
  right: 5px;
  width: 25px;
  height: 25px;
  background: url(${rightArrow}) no-repeat;
  background-size: cover;
  border: none;

  @media ${device.laptop} {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
