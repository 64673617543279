export class SealingSeats {
  constructor(
    public customerFirstName: string,
    public customerLastName: string,
    public customerEmail: string,
    public isBuyOnWeb: true,
    public dateOfBuying: Date,
    public timeOfBuying: Date,
    public isPaid: boolean,
    public seats: number,
    public projection: number
  ) {}
}
